import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";

let containers = document.getElementsByClassName("masonry-grid");

const masonries = [];

for (let i = 0; i < containers.length; i++) {
  let msnry = new Masonry(containers[i], {
    itemSelector: ".masonry-item",
    gutter: 10,
    percentPosition: true,
  });

  masonries.push(msnry);
}

// Once all images are loaded for all containers trigger a layout to avoid vertical stacking
imagesLoaded(containers, function (_context) {
  //console.log(`all images are loaded for ${_context}`);

  masonries.forEach((msnry) => {
    msnry.layout();
  });
});

// Add mobile nav handler
let mobileNav = document.querySelector("#mobile-nav");
let h2Element = document.querySelector("#mobile-nav h2");

if (h2Element) {
  h2Element.addEventListener("click", function (event) {
    let navClosed = mobileNav.classList.contains("mobile-nav-closed");

    navClosed = !navClosed;

    if (navClosed) {
      mobileNav.classList.remove("mobile-nav-open");
      mobileNav.classList.add("mobile-nav-closed");
    } else {
      mobileNav.classList.remove("mobile-nav-closed");
      mobileNav.classList.add("mobile-nav-open");
    }
  });
}
